.drawer {
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 10px 5px;
  flex: none;

  &:first-child {
    padding-inline-start: 10px;
  }

  &:last-child {
    padding-inline-end: 10px;
  }

  @include single-column('screen and (max-width: 630px)') {
    flex: auto;
  }

  @include limited-single-column('screen and (max-width: 630px)') {
    &,
    &:first-child,
    &:last-child {
      padding: 0;
    }
  }

  .wide & {
    min-width: 300px;
    max-width: 400px;
    flex: 1 1 200px;
  }

  @include single-column('screen and (max-width: 630px)') {
    :root & {
      //  Overrides `.wide` for single-column view
      flex: auto;
      width: 100%;
      min-width: 0;
      max-width: none;
      padding: 0;
    }
  }

  .react-swipeable-view-container & {
    height: 100%;
  }
}

.drawer__header {
  flex: none;
  font-size: 16px;
  background: lighten($ui-base-color, 8%);
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  overflow: hidden;

  & > * {
    display: block;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    padding: 15px 5px 13px;
    height: 48px;
    flex: 1 1 auto;
    color: $darker-text-color;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }

  a {
    transition: background 100ms ease-in;

    &:focus,
    &:hover {
      outline: none;
      background: lighten($ui-base-color, 3%);
      transition: background 200ms ease-out;
    }
  }
}

.search {
  position: relative;
  margin-bottom: 10px;
  flex: none;

  @include limited-single-column(
    'screen and (max-width: #{$no-gap-breakpoint})'
  ) {
    margin-bottom: 0;
  }
  @include single-column('screen and (max-width: 630px)') {
    font-size: 16px;
  }
}

.navigation-bar {
  padding: 10px;
  color: $darker-text-color;
  display: flex;
  align-items: center;

  a {
    color: inherit;
    text-decoration: none;
  }

  .acct {
    display: block;
    color: $secondary-text-color;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.navigation-bar__profile {
  flex: 1 1 auto;
  margin-inline-start: 8px;
  overflow: hidden;
}

.drawer--results {
  overflow-x: hidden;
  overflow-y: scroll;
}

.search-results__section {
  margin-bottom: 5px;

  h5 {
    background: darken($ui-base-color, 4%);
    border-bottom: 1px solid lighten($ui-base-color, 8%);
    cursor: default;
    display: flex;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    color: $dark-text-color;

    .fa {
      display: inline-block;
      margin-inline-end: 5px;
    }
  }

  .account:last-child,
  & > div:last-child .status {
    border-bottom: 0;
  }

  & > .hashtag {
    display: block;
    padding: 10px;
    color: $secondary-text-color;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: lighten($secondary-text-color, 4%);
      text-decoration: underline;
    }
  }
}

.drawer__pager {
  box-sizing: border-box;
  padding: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 4px;
}

.drawer__inner {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  background: lighten($ui-base-color, 13%);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  &.darker {
    background: $ui-base-color;
  }
}

.drawer__inner__mastodon {
  background: lighten($ui-base-color, 13%)
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.80078 31.757813" width="234.80078" height="31.757812"><path d="M19.599609 0c-1.05 0-2.10039.375-2.90039 1.125L0 16.925781v14.832031h234.80078V17.025391l-16.5-15.900391c-1.6-1.5-4.20078-1.5-5.80078 0l-13.80078 13.099609c-1.6 1.5-4.19883 1.5-5.79883 0L179.09961 1.125c-1.6-1.5-4.19883-1.5-5.79883 0L159.5 14.224609c-1.6 1.5-4.20078 1.5-5.80078 0L139.90039 1.125c-1.6-1.5-4.20078-1.5-5.80078 0l-13.79883 13.099609c-1.6 1.5-4.20078 1.5-5.80078 0L100.69922 1.125c-1.600001-1.5-4.198829-1.5-5.798829 0l-13.59961 13.099609c-1.6 1.5-4.200781 1.5-5.800781 0L61.699219 1.125c-1.6-1.5-4.198828-1.5-5.798828 0L42.099609 14.224609c-1.6 1.5-4.198828 1.5-5.798828 0L22.5 1.125C21.7.375 20.649609 0 19.599609 0z" fill="#{hex-color($ui-base-color)}"/></svg>')
    no-repeat bottom / 100% auto;
  flex: 1;
  min-height: 47px;
  display: none;

  > img {
    display: block;
    object-fit: contain;
    object-position: bottom left;
    width: 85%;
    height: 100%;
    pointer-events: none;
    user-select: none;
  }

  > .mastodon {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    cursor: inherit;
  }

  @media screen and (height >= 640px) {
    display: block;
  }
}

.pseudo-drawer {
  background: lighten($ui-base-color, 13%);
  font-size: 13px;
  text-align: start;
}

.drawer__backdrop {
  cursor: pointer;
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: rgba($base-overlay-background, 0.5);
}

@for $i from 0 through 3 {
  .mbstobon-#{$i} .drawer__inner__mastodon {
    @if $i == 3 {
      background:
        url('~flavours/glitch/images/wave-drawer.png')
          no-repeat
          bottom /
          100%
          auto,
        lighten($ui-base-color, 13%);
    } @else {
      background:
        url('~flavours/glitch/images/wave-drawer-glitched.png')
          no-repeat
          bottom /
          100%
          auto,
        lighten($ui-base-color, 13%);
    }

    & > .mastodon {
      background: url('~flavours/glitch/images/mbstobon-ui-#{$i}.png')
        no-repeat
        left
        bottom /
        contain;

      @if $i != 3 {
        filter: contrast(50%) brightness(50%);
      }
    }
  }
}
