.sensitive-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 4px;
  inset-inline-start: 4px;
  z-index: 100;
}

.sensitive-marker {
  margin: 0 3px;
  border-radius: 2px;
  padding: 2px 6px;
  color: rgba($primary-text-color, 0.8);
  background: rgba($base-overlay-background, 0.5);
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  opacity: 0.9;
  transition: opacity 0.1s ease;

  .media-gallery:hover & {
    opacity: 1;
  }
}
